<template>
  <div class="fr-accordions-group">
    <section
      class="fr-accordion"
      v-for="(item, index) in items"
      :key="`accordion-${index}`"
    >
      <h3 class="fr-accordion__title">
        <button
          class="fr-accordion__btn"
          :aria-expanded="activeAccordion === `accordion-${id}-${index}`"
          :aria-controls="`accordion-${id}-${index}`"
          @click="toggle(item.title, $event)"
        >
          {{ item.title }}
        </button>
      </h3>
      <div
        :class="[
          'fr-collapse',
          {
            'fr-collapse--expanded':
              activeAccordion === `accordion-${id}-${index}`
          },
          {
            'accordion-opened': activeAccordion === `accordion-${id}-${index}`
          }
        ]"
        :id="`accordion-${id}-${index}`"
      >
        <template v-if="!item.component">
          <div v-html="item.content"></div>
        </template>
        <component v-else :is="item.component"></component>
      </div>
    </section>
  </div>
</template>

<script>
import { normalizeString, sendTag } from '../utils/methodsUtils';

export default {
  props: {
    items: Array,
    id: String
  },
  data: function () {
    return {
      activeAccordion: null,
      clickedAlready: false
    };
  },
  methods: {
    toggle(tagName, e) {
      if (!this.activeAccordion && !this.clickedAlready) {
        this.tagAccordion(tagName);
        this.clickedAlready = true;
      }
      const id = e.target.getAttribute('aria-controls');
      this.activeAccordion = this.activeAccordion === id ? null : id;
    },
    tagAccordion(tagName) {
      sendTag(normalizeString(tagName), 'aide_bloc_telephone', '', '');
    }
  }
};
</script>
<style lang="scss" scoped>
.accordion-opened {
  max-height: none;
  --collapse: 0px;
}
</style>
