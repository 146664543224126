<template>
  <div>
    <h1 class="fs-2500">Aide</h1>
    <p>Toutes les réponses à vos questions techniques.</p>
    <div class="fr-container qf-container">
      <div class="fr-grid-row mb-5">
        <div class="fr-col-12 fr-col-md-6 bloc-textimage bloc-questions fr-m-a">
          <a
            href="#"
            @click="
              tagAide('Échangez avec notre assistant virtuel', 'avatar');
              openChatbot($event);
            "
            class="no-box-shadow no-underline"
            ><img
              src="../assets/img/avatar_11.svg"
              alt="image représentant un personnage avec un casque micro avec écrit à coté en quoi puis-je vous aider"
              class="avatar-img"
          /></a>

          <p class="h2 font-weight-bold avatar-subtext">
            Une difficulté pour utiliser Portailpro.gouv ?
          </p>

          <div class="fr-plus bloc-textimage--lien fr-m-5w">
            <a
              href="#"
              class="lnk fr-link fr-pl-0"
              @click="
                tagAide('Échangez avec notre assistant virtuel', 'avatar');
                openChatbot($event);
              "
              title="Échangez avec notre assistant virtuel"
              >Échangez avec notre assistant virtuel<span
                class="fr-icon-arrow-right-line pl-2"
                aria-hidden="true"
              ></span
            ></a>
          </div>
        </div>
        <div class="fr-col-12 fr-col-md-6 bloc-questions">
          <div class="fr-grid-row faq">
            <div class="fr-ml-4w fr-my-3w qf">
              <h2 class="h4 font-weight-bold">Questions fréquentes</h2>
            </div>
            <div class="fr-plus fr-ml-4w fr-my-4w">
              <router-link
                class="lnk fr-link fr-pl-0 no-after"
                title="Consulter la foire aux questions"
                @click="tagAide('Voir la foire aux questions', '')"
                :to="{ name: 'Foire aux questions' }"
                >Voir la foire aux questions<span
                  class="fr-icon-arrow-right-line pl-1"
                  aria-hidden="true"
                ></span
              ></router-link>
            </div>
          </div>
          <div class="fr-grid-row">
            <div class="fr-col-12">
              <ul class="liste-condensee">
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q: 'comment-creer-mon-compte'
                      }
                    }"
                    class="no-after"
                    title="« Comment créer mon compte ? » - Page foire aux questions"
                    @click="tagAide('Comment créer mon compte', '')"
                  >
                    Comment créer mon compte ?
                  </router-link>
                </li>
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q:
                          'que-faire-si-je-ne-recois-pas-mon-code-par-sms-pour-finaliser-mon-inscription'
                      }
                    }"
                    class="no-after"
                    title="« Que faire si je ne reçois pas mon code par SMS pour finaliser mon inscription ? » - Page foire aux questions"
                    @click="
                      tagAide(
                        'Que faire si je ne reçois pas mon code par SMS pour finaliser mon inscription',
                        ''
                      )
                    "
                  >
                    Que faire si je ne reçois pas mon code par SMS pour
                    finaliser mon inscription ?
                  </router-link>
                </li>
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q: 'pourquoi-rattacher-un-compte-tiers'
                      }
                    }"
                    class="no-after"
                    title="« Pourquoi rattacher un compte tiers ? » - Page foire aux questions"
                    @click="tagAide('Pourquoi rattacher un compte tiers', '')"
                  >
                    Pourquoi rattacher un compte tiers ?
                  </router-link>
                </li>
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    title="« Peut-on créer un compte tiers impots.gouv.fr, urssaf.fr, douane.gouv.fr ou net-entreprises.fr depuis Portailpro.gouv ? » - Page foire aux questions"
                    class="no-after"
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q:
                          'peut-on-creer-un-compte-tiers-impots-gouv-fr-urssaf-fr-douane-gouv-fr-ou-net-entreprises-fr-depuis-portailpro-gouv'
                      }
                    }"
                    @click="
                      tagAide(
                        'Peut-on créer un compte tiers impots.gouv.fr, urssaf.fr, douane.gouv.fr ou net-entreprises.fr depuis Portailpro.gouv',
                        ''
                      )
                    "
                  >
                    Peut-on créer un compte tiers impots.gouv.fr, urssaf.fr,
                    douane.gouv.fr ou net-entreprises.fr depuis Portailpro.gouv
                    ?
                  </router-link>
                </li>
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    title="« Qu'est-ce que le rôle « Administrateur Général de l'Entreprise » (AGE) ? » - Page foire aux questions"
                    class="no-after"
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q:
                          'qu-est-ce-que-le-role-administrateur-general-de-l-entreprise-age'
                      }
                    }"
                    @click="
                      tagAide(
                        'Quest-ce que le rôle « Administrateur Général de lEntreprise » (AGE)',
                        ''
                      )
                    "
                  >
                    Qu'est-ce que le rôle « Administrateur Général de
                    l'Entreprise » (AGE) ?
                  </router-link>
                </li>
                <li class="fr-highlight no-box-shadow fr-mb-2w">
                  <router-link
                    title="« Qu'est-ce qu'une habilitation ? » - Page foire aux questions"
                    class="no-after"
                    :to="{
                      name: 'Foire aux questions',
                      query: {
                        q: 'qu-est-ce-qu-une-habilitation'
                      }
                    }"
                    @click="tagAide('Qu est-ce qu une habilitation', '')"
                  >
                    Qu'est-ce qu'une habilitation ?
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <h2 class="fs-b">Formulaire d'assistance technique</h2>
      <p>
        Pour toute question concernant vos déclarations, paiements ou démarches,
        merci d'utiliser votre
        <span @click="tagAide('messagerie sécurisée', 'tel')"
          ><router-link
            :to="{ name: 'Messagerie' }"
            title="messagerie sécurisée"
            class="lnk"
            >messagerie sécurisée</router-link
          ></span
        >
      </p>

      <div class="fr-grid-row">
        <!-- #PCR-2504 : RGAA audit#1 (Vue) > Présentation info (10) > CSS (10.1) -->
        <!-- height calculé depuis le style css 'iframe-style'-->
        <iframe
          title="Assistance aux téléprocédures des professionnels Formulaire pour les utilisateurs de Portailpro.gouv"
          id="iframe-form"
          width="100%"
          :src="param"
          frameborder="0"
          scrolling="no"
          ref="iframe"
          class="iframe-style"
          v-if="!blocked"
        ></iframe>

        <div class="mb-5 bloc-formulaire" v-if="!blocked">
          En cas de difficulté pour utiliser le formulaire d'assistance
          technique :
          <br />
          <a
            :href="param"
            class="no-box-shadow btn pr-btn iframe-block"
            target="_blank"
            title="Accès au formulaire d'assistance technique en cas de difficulté"
            @click="tagAide('Ouvrir le formulaire', 'tel')"
            ><i class="ri-external-link-line"></i
            ><span class="btn-content">Ouvrir le formulaire</span></a
          >
        </div>
        <div class="fr-col-md-8 bloc-formulaire" v-if="blocked">
          <h2 class="fs-b">Le cookie du formulaire a été bloqué</h2>
          <p>
            Une erreur est survenue empêchant le formulaire d'être utilisé
            depuis cette page.<br />
            Nous vous invitons à le consulter directement dans un nouvel onglet.
          </p>

          <a
            :href="param"
            class="no-box-shadow btn pr-btn iframe-block"
            target="_blank"
            title="Accès au formulaire d'assistance technique - Site externe - Nouvel onglet"
            @click="tagAide('Ouvrir le formulaire', 'tel')"
            ><i class="ri-external-link-line"></i
            ><span class="btn-content">Ouvrir le formulaire</span></a
          >
        </div>
        <div class="fr-col-md-4" v-if="blocked">
          <img
            src="../assets/img/cookies.svg"
            alt="image représentant un écran avec devant un cookie"
            class=""
          />
        </div>
      </div>
      <div class="fr-grid-row mb-5">
        <div class="fr-col-12 fr-col-md-6">
          <AccordionGroup
            id="aide"
            :items="[
              {
                title: ' Assistance téléphonique',
                content:
                  '<p style=\'border: 1px solid #ddd;\' class=\'p-4\'><a href=\'tel:0809400210\' @click=\'tagAide(\'numéro de téléphone\', \'tel\')\' style=\'box-shadow:none !important; text-decoration:none !important; color: #0D6635; font-size: 18px; font-weight: bold;\'><i class=\'ri-phone-line mr-2\'></i>0 809 400 210</a><br /><span class=\'text--xsmall mb-2\' style=\'color: #9c9c9c;\'>(service gratuit + coût de l\'appel)</span><br /><span class=\'text--xsmall\'> du lundi au vendredi de 08 h 00 et 19 h 30</span></p>'
              }
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionGroup from '../components/AccordionGroup.vue';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { normalizeString, sendTag } from '../utils/methodsUtils';

export default {
  name: 'Aide',
  components: {
    AccordionGroup
  },
  mixins: [SkipLinkMixin],
  data: function () {
    return {
      activeAccordion: null,
      formURL: process.env.VUE_APP_URL_AIDE,
      blockedCookie: false
    };
  },
  mounted() {
    window.addEventListener('message', this.resizeIframe, false);
  },
  unmounted() {
    window.removeEventListener('message', this.resizeIframe, false);
  },
  methods: {
    resizeIframe(e) {
      let iframe = document.getElementById('iframe-form');
      if (iframe) {
        // message that was passed from iframe page
        const message = e.data;
        iframe.style.height = message.height + 40 + 'px';
        iframe.style.width = message.width + 'px';
        let specificClass = 'resizedOnce';
        if (iframe.classList.contains(specificClass)) {
          window.scrollTo({ top: iframe.offsetTop - 20, behavior: 'smooth' });
        }
        iframe.classList.add(specificClass);
        this.blockedCookie = message.issetCookies ? false : true;
      }
    },
    toggle(e) {
      this.activeAccordion = e.target.getAttribute('aria-controls');
    },
    openChatbot(event) {
      if (event) {
        event.preventDefault();
      }
      window.SmartlyWebchat.openWidget();
    },
    getChapter1(bloc) {
      if (bloc === 'avatar') {
        return 'aide_bloc_avatar';
      } else if (bloc === 'tel') {
        return 'aide_bloc_telephone';
      } else {
        return 'aide_bloc_questions_frequentes';
      }
    },
    tagAide(tagName, bloc) {
      sendTag(normalizeString(tagName), this.getChapter1(bloc), '', '');
    }
  },
  computed: {
    ...mapGetters(['siren']),
    blocked() {
      return this.blockedCookie;
    },
    param() {
      return this.siren
        ? this.formURL + '?nosiren=' + this.siren
        : this.formURL;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: $base-color;
  font-weight: bold;
}
.liste-condensee {
  list-style: none;
  padding-left: 0;

  li {
    background-color: #f9f8f6;
    line-height: 3.5vw;
    margin-bottom: 13px;
    border-left: 4px solid $base-color;
    padding-left: 0px !important;
    display: flex;
    align-items: center;
  }

  li:hover {
    background-image: linear-gradient(
      0deg,
      rgba(233, 234, 224, 0.5),
      rgba(233, 234, 224, 0.5)
    );
  }

  a {
    margin: 11.6px 0 11.6px 44px;
    font-size: 20px;
    line-height: 1.4;
    vertical-align: middle;
    box-shadow: none !important;
  }
}

.fr-plus {
  flex: 1;
  text-align: right;
}

.img-responsive {
  padding-top: 80px;
}

.bloc-textimage,
.bloc-textimage--lien {
  text-align: center;
}

.faq {
  align-items: center;
}

.lnk {
  color: $base-color;
  text-decoration: none !important;
  box-shadow: none;
}

.avatar-subtext {
  width: 400px;
  height: 68px;
  margin: 0px auto 35px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
}
.avatar-img {
  padding-top: 100px;
  padding-bottom: 26px;
  width: 226px;
  height: auto;
  cursor: pointer;
}

.bloc-formulaire {
  margin-top: 1.5rem;
}

@media (max-width: 600px) {
  .liste-condensee {
    a {
      font-size: 14px !important;
      margin: 10px 0 10px 10px !important;
      width: 100%;
      display: inline-block;
    }
  }
  .fr-plus {
    margin-top: 14px !important;
    margin-bottom: 35px !important;
    width: 288px;
    text-align: center;
    margin-left: auto !important;
    width: 90%;
  }
  .faq {
    display: block;
  }
  .avatar-subtext {
    width: 90%;
    font-size: 28px;
  }
}

.no-box-shadow {
  box-shadow: none;
}

.pr-btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  margin-top: 0.5rem;
}
.btn-content {
  line-height: 1.7rem;
}

.ri-external-link-line {
  font-size: 17px;
  padding-right: 10px;
}

.iframe-block {
  padding: 8px 28px 8px 20px;
  display: inline-flex;
  height: 2.5rem;
}
a.iframe-block:hover {
  background-color: #1212ff;
}
a.iframe-block::after {
  display: none;
}
.liste-condensee {
  a {
    text-decoration: underline !important;
    background-image: none;
    color: #3a3a3a;
  }
}
a {
  --underline-hover-width: 0;
  &:hover {
    --underline-hover-width: var(--underline-max-width);
  }
}
a.no-underline {
  background-image: none;
}
.fr-icon-arrow-right-line::before {
  --icon-size: 1rem;
}
.no-after::after {
  display: none;
}

@media (max-width: 990px) {
  #iframe-form {
    width: 100% !important;
  }

  .avatar-img {
    padding-top: initial;
    padding-bottom: initial;
  }

  .qf {
    margin-left: auto !important;
    margin-top: initial !important;

    h2 {
      font-size: 24px;
    }
  }

  .qf-container {
    padding-left: 0;
    padding-right: 0;

    .bloc-questions {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .fr-highlight {
    margin-left: auto;
  }
}
</style>
